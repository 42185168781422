/**
 * Type: ページ
 * What: 絶景3兄弟 SMBC日興証券-つりZOページ
 */
import React, { useEffect, useState } from 'react';
import { graphql, Link } from 'gatsby';
import { scroller } from 'react-scroll';
import Layout from '../../components/Layout';
import SEO from '../../components/Seo';
import { Btn, Button, Text, TitleZekkei } from '../../components/Btn';
import Image from '../../util/Image';
import BreadcrumbComponent from '../../components/Breadcrumb';
import SimpleSwiper from '../../components/Slider.fade';
import RfStatusComponent from '../../components/PostList/RfStatusComponent';
import { Accordion } from '../../components/Accordion';
import useMediaQuery from '../../util/useMediaQuery';
import ModalContent from '../../components/ZekkeiModal';

const pageTitle = 'つりZO SMBC日興証券';
const pageDescription =
  'E・ZO FUKUOKAの屋上アトラクション。E･ZO最高地点・地上約60mからのスタート！一人乗りのぶら下がり式レールコースター。興奮しっぱなしの全長約300ｍ超のコースを駆け抜けろ！';
const pageSlug = 'zekkei-brothers';
// const pageLogo = `logo-${pageSlug}.png`;
// const pagebtnLogo = `btn-${pageSlug}.png`;

const sliderDataobj = [
  {
    filename: 'kv-zekkei-tsurizo.jpg',
  },
];
export const query = graphql`
  {
    site {
      siteMetadata {
        ticket
        ticketReady
        uuidTsuriZO
        zekkeiReady
      }
    }
  }
`;

const mq = useMediaQuery;

// Data Props Template
const Template = ({ data }) => {
  const { ticket } = data.site.siteMetadata;
  const uuid = data.site.siteMetadata.uuidTsuriZO;
  const isTicket = data.site.siteMetadata.ticketReady;
  const isZekkei = data.site.siteMetadata.zekkeiReady;

  const scrollToTarget = (elem, offset) => {
    const $offset = offset || -110;
    scroller.scrollTo(elem, {
      duration: 1200,
      delay: 0,
      offset: $offset,
      smooth: 'easeOutQuint',
    });
  };

  const openPdf = () => {
    /* eslint no-undef: 0 */
    const lang = WOVN.io.getCurrentLang().code;
    const href = `https://e-zofukuoka.com/files/zekkei-brothers/rule-tsurizo-${lang}.pdf`;
    window.open(href);
  };

  return (
    <Layout pageSlug={pageSlug}>
      <SEO title={pageTitle} description={pageDescription} />
      <BreadcrumbComponent hierarchy={2} bread2title={pageTitle} />
      <section className="pageMain bg-8 arrow">
        <div className="kv-wrap">
          <div className="kv-inner">
            <SimpleSwiper data={sliderDataobj} />
          </div>
          <div className="container">
            <div className="inner">
              <div className="pageMain-header">
                <div className="floorNo c-8">
                  <span>R</span>
                  <span>F</span>
                </div>
                <h2 className="ttl">
                  <Image src="logo-zekkei-tsurizo-h.png" alt={pageTitle} />
                </h2>
              </div>

              {isZekkei === '1' ? <RfStatusComponent col="2" /> : null}

              <div className="overview">
                <p className="text">
                  <span className="fwb">
                    E･ZO最高地点・地上約60mからのスタート！一人乗りのぶら下がり式レールコースター。興奮しっぱなしの全長約300ｍ超のコースを駆け抜けろ！
                  </span>
                  <br />
                  ※ビル屋上に設置されたレールコースターとして日本初
                </p>
              </div>
              <p className="has-text-align-center attention has-text-weight-bold mb-4 text is-3">
                屋外アトラクションのため体験中の気温対策はお客様ご自身でお願いします。
              </p>
              <p className="has-text-align-center attention has-text-weight-bold mb-4 text is-3">
                チケットは当日券のみ、BOSS E・ZO FUKUOKA
                <br />
                8Fに設置の専用券売機にてお買い求めいただけます。
              </p>
              {/* <div className="btn-group mt-5">
                <Link
                  to="/news/info/20230311252/"
                  className="btn-def caution-ticket-btn narrow"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <span>
                    新型コロナウイルス感染防止の対策について
                  </span>
                </Link>
              </div> */}
              <div className="youtube-wrap mt-6 mb-0 cn-hide">
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/6Ex2zdiGj3M"
                  frameBorder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            </div>
          </div>
        </div>

        {/*        <div className="container">
          <div className="inner">
            <div className="section-link">
              <ul>
                <li><a href="#attraction" onClick={() => scrollToTarget('attraction', -66)}><span className="arrow">劇場紹介</span></a></li>
                <li><a href="#information" onClick={() => scrollToTarget('information', -30)}><span className="arrow">料金</span></a></li>
                <li><a href="#qa"><span className="arrow">よくある質問</span></a></li>
              </ul>
            </div>
          </div>
        </div> */}
      </section>
      {/*      <section className="section sc-topics bs-topics" id="topics">
        <div className="container">
          <h2 className="headline"><span>Topics</span></h2>
          <PostListComponent />
          <div className="btn-wrap">
            <Btn href="/news/" className="btn-l btn-ticket bc-1">
              <Text>
                一覧をみる
              </Text>
            </Btn>
          </div>
        </div>
      </section> */}
      <section className="section sc-attraction bg-13 none" id="attraction">
        <div className="container">
          {mq('pc') ? (
            <h2 className="title is-2 jp shadow3">
              <TitleZekkei width={1032} shadow="50.5%">
                E・ZO福岡の最高地点からのスタート！
              </TitleZekkei>
              <TitleZekkei width={1032} shadow="50.5%">
                前代未聞のコースを駆け抜ける！
              </TitleZekkei>
            </h2>
          ) : (
            <h2 className="title is-2 jp shadow3 lh0">
              <TitleZekkei width={760} shadow="50.5%">
                E・ZO福岡の最高地点からの
              </TitleZekkei>
              <TitleZekkei width={760} shadow="50.5%">
                スタート！前代未聞のコースを
              </TitleZekkei>
              <TitleZekkei width={760} shadow="50.5%">
                駆け抜ける！
              </TitleZekkei>
            </h2>
          )}
          <div className="content mb60">
            <p className="text is-2 is-3-sp ls-5 lh-9">
              ビル最上階から更に高さ１０ｍのデッキを登り、いよいよスタート！その高さはなんと地上６０ｍ・・・ゆっくりとすべり出すと、玄界灘の絶景があなたを包み込む！
            </p>
          </div>

          <div className="flow-row">
            <div className="col6 sp-mb30">
              <div className="col6-inner ">
                <Image filename="img-tsurizo01.jpg" />
              </div>
            </div>
            <div className="col6">
              <div className="col6-inner ">
                <Image filename="img-tsurizo02.jpg" />
              </div>
            </div>
          </div>
          <div className="flow-row gap0">
            <div className="col6">
              <div className="col6-inner">
                <Image filename="img-tsurizo03.jpg" />
              </div>
            </div>
            <div className="col6">
              <div className="col6-inner">
                <Image filename="img-tsurizo04.jpg" />
              </div>
            </div>
            <h3 className="distinguishing_text">
              <span>スリル満点の</span>
              <br />
              感動絶景体験!!
            </h3>
          </div>

          {mq('pc') ? (
            <h2 className="title is-2 jp shadow3 mb20">
              <TitleZekkei width={1132} shadow="50.5%">
                長さだけじゃない！楽しめるポイントがたくさん！
              </TitleZekkei>
            </h2>
          ) : (
            <h2 className="title is-2 jp shadow3 lh-0">
              <TitleZekkei width={760} shadow="50.5%">
                長さだけじゃない！
              </TitleZekkei>
              <TitleZekkei width={800} shadow="50.5%">
                楽しめるポイントがたくさん！
              </TitleZekkei>
            </h2>
          )}

          <div className="content mb60">
            <p className="text is-2 is-3-sp ls-5 lh-9">
              全長300mを超えるコースを上下左右に揺られながら一気に駆け抜ける！E・ZOでしか体験できない爽快感で未知の世界へといざないます！
            </p>
          </div>
          <div className="ov-scroll mb80">
            <div className="flow-row gap20 w300">
              <div className="col6">
                <p className="title is-2 shadow3 tal wa">
                  <TitleZekkei width="160">Start</TitleZekkei>
                </p>
                <div className="arrow arrowr">
                  <div className="col4-inner ">
                    <Image filename="img-tsurizo05.jpg" />
                  </div>
                  <Image src="accordion-arrow.svg" />
                </div>
                <span className="text is-1 ls-5 fwb">
                  地上６０M・・・玄界灘の絶景が広がる！
                </span>
              </div>
              <div className="col6">
                <div className="arrow arrowr">
                  <div className="col4-inner ">
                    <Image filename="img-tsurizo06.jpg" />
                  </div>
                  <Image src="accordion-arrow.svg" />
                </div>
                <span className="text is-1 ls-5 fwb">
                  普段は見ることのできないドームの屋根！ドームを見下ろしながら滑走を楽しめる！
                </span>
              </div>
              <div className="col6">
                <div className="arrow arrowl">
                  <div className="col4-inner ">
                    <Image filename="img-tsurizo07.jpg" />
                  </div>
                  <Image src="accordion-arrow.svg" />
                </div>
                <span className="text is-1 ls-5 fwb">
                  最大傾斜！ビルを飛び出しての滑走はスリル満点！
                </span>
              </div>
              <div className="col6">
                <p className="title is-2 shadow3 tar wa">
                  <TitleZekkei width="130">Goal</TitleZekkei>
                </p>
                <div className="arrow arrowl">
                  <div className="col4-inner ">
                    <Image filename="img-tsurizo08.jpg" />
                  </div>
                  {mq('pc') && <Image src="accordion-arrow.svg" />}
                </div>
                {/* <span className="text is-1 ls-5 fwb">普段は見ることのできないドームの屋根！ドームを見下ろしながら滑走を楽しめる！</span> */}
              </div>
            </div>
          </div>
          {mq('pc') ? (
            <h2 className="title is-2 jp shadow3 mb20">
              <TitleZekkei width={1032} shadow="50.5%">
                「つりZO」フォトサービス
              </TitleZekkei>
            </h2>
          ) : (
            <h2 className="title is-2 jp shadow3 lh-0">
              <TitleZekkei width={760} shadow="50.5%">
                「つりZO」フォトサービス
              </TitleZekkei>
            </h2>
          )}
          <div className="content mb20">
            <p className="text is-2 is-3-sp ls-5 lh-9">
              体験中の貴重な瞬間を撮影するサービスを実施しております。季節によって限定フレームもございますので、つりZO体験の記念にぜひご利用ください。
            </p>
          </div>
          <div className="flow-row gap20 w300">
            <div className="col6">
              <div className="arrow arrowr">
                <div className="col4-inner sp-mb20 mb0">
                  <Image filename="img-tsurizophoto01.jpg" />
                </div>
              </div>
            </div>
            <div className="col6">
              <div className="arrow arrowr">
                <div className="col4-inner mb0">
                  <Image filename="img-tsurizophoto02.jpg" />
                </div>
              </div>
              <div className="credit c-4 mb-6">
                ※画像はイメージです。時期によってフレームは異なります。
              </div>
            </div>
          </div>
          <div className="content mb60">
            <p className="text is-2 is-3-sp ls-5 lh-9">
              ＜価格（税込）＞
              <br />
              データ渡し：1枚　500円
              <br />
              データ＋現像渡し ：1セット1,200円
            </p>
            <p className="text is-2 is-3-sp ls-5 lh-9">
              ＜購入方法＞
              <br />
              体験後、スタッフがお渡しする購入カードをお受け取りいただき、9Fロッカー横設置の端末を操作のうえご購入ください。
            </p>
          </div>
        </div>
      </section>
      <section className="section bg-13" id="information">
        <div className="container">
          <div className="inner-slim bg-14">
            <h3 className="headline">
              <span>Information</span>
            </h3>

            <h4 className="text fwb is-3 is-4-sp mb15">料金・対象</h4>
            <div className="table-base">
              <table>
                <tbody>
                  <tr>
                    <th>料金</th>
                    <td>
                      <dl className="base-dl">
                        <dt>ピーク</dt>
                        <dd>
                          <b>2,500円</b>
                        </dd>
                      </dl>
                      <dl className="base-dl">
                        <dt>レギュラー</dt>
                        <dd>
                          <b>1,800円</b>
                        </dd>
                      </dl>
                      <p className="mt30">
                        各日程のレギュラー・ピークは空席情報カレンダーでご確認ください。
                      </p>
                      <p>
                        <a href="/ticket/" className="tx-link">
                          空席情報カレンダーはこちら
                        </a>
                      </p>
                    </td>
                  </tr>
                  <tr>
                    <th>対象年齢</th>
                    <td>13歳～64歳</td>
                  </tr>
                  <tr>
                    <th>身長</th>
                    <td>130㎝以上</td>
                  </tr>
                  <tr>
                    <th>体重</th>
                    <td>30㎏～90㎏未満（着衣を含みます）</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <article>
              <p className="mb15">【以下に該当する方はご利用いただけません】</p>
              <ol className="mb30">
                <li>
                  ・当施設は安全面の理由から、身長・体重・年齢で下記利用制限を設けております。
                </li>
                <li>
                  ①12歳以下、65歳以上、身長130㎝未満、着衣を含み体重が30Kg未満または90Kg以上の方
                </li>
                <li>
                  ②・酒気を帯びた方
                  <br />
                  ・妊娠中の方
                  <br />
                  ・心疾患をお持ちの方
                  <br />
                  ・ご自身での自立歩行が難しい方
                  <br />
                  ・その他健康上不安のある方
                  <br />
                  ・ハーネスが正しく（フィットした状態で）装着できない
                </li>
              </ol>
            </article>
            <div className="inner-slim p00 bg-14">
              <Accordion
                Name="利用規約はこちら"
                Title="利用規約"
                Close="利用規約を閉じる"
                bg="bg-14"
                open
              >
                {/* <h3>段落タイトル段落タイトル段落タイトル</h3> */}
                <div className="content">
                  <p className="list-mark3">
                    ・当施設内での怪我、損失、損害、トラブルなどにつきまして、弊社の責めに帰すべき事由がある場合を除き、弊社は一切の責任を負いません。
                  </p>
                  <p className="list-mark3">
                    ・スタッフにより安全にご参加いただけないと判断した場合、参加をお断りする場合がございます。
                  </p>
                  <p className="list-mark3">
                    ・スタッフの指示には必ず従って下さい。指示及び注意事項に従わなかった場合の怪我などの障害及び損害については責任を負いかねます。
                  </p>
                  <p className="list-mark3">
                    ・安全確認のため、身長・体重の測定をお願いする場合がございます。測定後に利用制限に該当する場合はご参加をお断りさせていただきます。
                  </p>
                </div>
              </Accordion>
            </div>
            <article className="mt-5">
              <p className="has-text-align-center has-text-weight-bold mb-4 is-size-6">
                ※ご利用当日に利用同意書が必要です。
              </p>
              {/* {isTicket === '1' ? (
                <ModalContent ticket={ticket} uuid={uuid} page="tsurizo" />
              ) : null} */}
              <div
                className="btn-wrap mb-3"
                data-sal="slide-up"
                data-sal-easing="ease-out-expo"
              >
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                  className="btn-regular has-text-align-center"
                  onClick={openPdf}
                >
                  <span>つりZO同意書・利用規約（PDF）</span>
                  <svg
                    viewBox="0 0 490.8 490.8"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M135.685,3.128c-4.237-4.093-10.99-3.975-15.083,0.262c-3.992,4.134-3.992,10.687,0,14.82l227.115,227.136L120.581,472.461c-4.237,4.093-4.354,10.845-0.262,15.083c4.093,4.237,10.845,4.354,15.083,0.262c0.089-0.086,0.176-0.173,0.262-0.262l234.667-234.667c4.164-4.165,4.164-10.917,0-15.083L135.685,3.128z" />
                    <path d="M128.133,490.68c-5.891,0.011-10.675-4.757-10.686-10.648c-0.005-2.84,1.123-5.565,3.134-7.571l227.136-227.115L120.581,18.232c-4.171-4.171-4.171-10.933,0-15.104c4.171-4.171,10.933-4.171,15.104,0l234.667,234.667c4.164,4.165,4.164,10.917,0,15.083L135.685,487.544C133.685,489.551,130.967,490.68,128.133,490.68z" />
                  </svg>
                </div>
              </div>
              {/* <div className="btn-group mt-5 mb-6">
                <Link
                  to="/news/info/20230311252/"
                  className="btn-def caution-ticket-btn narrow"
                  data-sal="slide-up"
                  data-sal-easing="ease-out-expo"
                >
                  <span>
                    新型コロナウイルス感染防止の対策について
                  </span>
                </Link>
              </div> */}
              <p className="has-text-align-center attention has-text-weight-bold mb-4 is-size-6">
                チケットは当日券のみ、BOSS E・ZO FUKUOKA
                <br />
                8Fに設置の専用券売機にてお買い求めいただけます。
              </p>
              <h4 className="text fwb is-3 is-4-sp mb15">注意事項</h4>
              <ol className="mb30">
                <li>・状況により、ご利用をお待ちいただく場合がございます。</li>
                <li>
                  ・屋外アトラクションのため体験中の気温対策はお客様ご自身でお願いします。
                </li>
                <li>
                  ・天候不良等によりご参加いただけない場合は払い戻しをさせていただきます。
                </li>
                <li>
                  ・スカートやワンピース、ももが隠れる丈のコートなどハーネスの着用に支障がある衣類を着用してのご利用はできません。
                </li>
                <li>・衣服が擦れたり、汚れたりする場合があります。</li>
                <li>
                  ・履物を装着してのご利用はできません。ご利用時は専用のケースに収納いただきます。
                </li>
                <li>
                  ・荷物（携帯電話、カメラなど身の回りの所持品含む）やマフラー、コート、装飾品（ネックレス・ブレスレット・ピアスなど外れる恐れのあるもの）は全てロッカーに預けていただきます。装飾品が外せない場合はご参加いただけません。
                </li>
                <li>
                  ・風の影響によりコースの途中で停止する場合がございますが、安全上問題はなく、スタッフが誘導しますのでその指示に従ってください。ゴール地点までの滑走に最大30分程度要する場合があります。
                </li>
              </ol>
            </article>
          </div>
        </div>
      </section>
      <section className="section sc-flow bg-14" id="flow">
        <div className="container">
          <h2 className="headline">
            <span>Flow</span>
          </h2>
          <div className="border-box bs-8 stack">
            <Image filename="img-flow-tsurizo.png" />
          </div>

          <div className="content border-box bg-12 tsurizo">
            <h3>8F 受付へお越しください</h3>
            <p>
              <span>1</span>
              受付で手続きをする
              <br />
              ・チケットをご提示ください。チケット情報の確認を行います。
              <br />
              ・ロッカー専用コインをお渡しします。
              <br />
            </p>
            <p>
              <span>2</span>
              同意書への記載。内容をよく確認のうえ記載をお願いいたします
            </p>
            <p>
              <span>3</span>
              専用ロッカーへの手荷物収納 ※手荷物は全てお預けください
            </p>
            <p>
              <span>4</span>
              スタートまで待機列にてお待ちください
            </p>
          </div>

          <div className="inner p00 bg-14">
            <div className="introduction">
              <p className="tac fwb">▼　その他の屋上アトラクションを見る　▼</p>
              <div className="row j-center colum-sp">
                <div className="col">
                  <Link to="/zekkei-brothers/">
                    <Image
                      filename="logo-zekkei-brothers-l.png"
                      alt="絶景3兄弟 SMBC日興証券"
                    />
                    <span className="text fwb">
                      絶景3兄弟 SMBC日興証券TOPページへ戻る
                    </span>
                  </Link>
                </div>
                <div className="col">
                  <Link to="/zekkei-brothers/subezo/">
                    <Image
                      filename="logo-zekkei-subezo.png"
                      alt="すべZO | 絶景3兄弟 SMBC日興証券"
                    />
                  </Link>
                </div>
                <div className="col">
                  <Link to="/zekkei-brothers/nobozo/">
                    <Image
                      filename="logo-zekkei-nobozo.png"
                      alt="のぼZO | 絶景3兄弟 SMBC日興証券"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};
export default Template;
